import React from "react"
import Layout from '../components/layout/layout';
import {
  Header,
  Page404,
  SEO,
} from "../components";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header/>
    <Page404/>
  </Layout>
)

export default NotFoundPage
